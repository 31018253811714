import 'react-hot-loader/patch';
import 'reflect-metadata';
import '@shared/i18n';

import 'reset-css';

import DateFnsUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import { OptionsProvider } from '@landlord/contexts/OptionsContext';
import { createTheme } from '@landlord/theme/utils/create-theme';
import store from '@redux/store';
import { ConfigProvider } from '@shared/contexts/ConfigContext';
import { AppId } from '@shared/contexts/ConfigContext';
import { UnsavedChangesProvider } from '@shared/contexts/UnsavedChangesContext';
import { history } from '@shared/utils/history';
import { queryClient } from '@shared/utils/http-client';

import { App } from './App';

import '@shared/assets/scss/iconFonts.scss';
import '@shared/assets/scss/mapOverride.scss';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createTheme();

render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider appId={AppId.Landlord}>
            <UnsavedChangesProvider>
              <OptionsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </OptionsProvider>
            </UnsavedChangesProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
