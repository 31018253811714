import { createContext, FC, useContext, useMemo } from 'react';

import { useLandlordConstants } from '@shared/contexts/ConfigContext';
import { ConfigLandlordConstants } from '@shared/models/config';
import { stringObjectToOptions } from '@shared/utils/options';

// import { useConfig } from '@core/contexts/ConfigContext';

const getOptionsValue = (constants: ConfigLandlordConstants) => {
  return {
    buildingTypeOptions: stringObjectToOptions(constants.buildingTypes),
    discountReasonOptions: stringObjectToOptions(constants.matchDiscountReasonOptions),
  };
};

type UseOptionResult = ReturnType<typeof getOptionsValue>;

const OptionsContext = createContext<UseOptionResult>({} as UseOptionResult);

export const useOptions = () => {
  return useContext(OptionsContext);
};

export const OptionsProvider: FC = ({ children }) => {
  // const { } = useConfig();
  const { constants } = useLandlordConstants();

  const result = useMemo(() => getOptionsValue(constants), [constants]);

  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};
